import request from '@/utils/request'

// 查询商品-价格区间列表
export function listGoodsPriceRrange(query) {
  return request({
    url: '/platform/goods-price-range/list',
    method: 'get',
    params: query
  })
}

// 查询商品-价格区间分页
export function pageGoodsPriceRrange(query) {
  return request({
    url: '/platform/goods-price-range/page',
    method: 'get',
    params: query
  })
}

// 查询商品-价格区间详细
export function getGoodsPriceRrange(data) {
  return request({
    url: '/platform/goods-price-range/detail',
    method: 'get',
    params: data
  })
}

// 新增商品-价格区间
export function addGoodsPriceRrange(data) {
  return request({
    url: '/platform/goods-price-range/add',
    method: 'post',
    data: data
  })
}

// 修改商品-价格区间
export function updateGoodsPriceRrange(data) {
  return request({
    url: '/platform/goods-price-range/edit',
    method: 'post',
    data: data
  })
}

// 删除商品-价格区间
export function delGoodsPriceRrange(data) {
  return request({
    url: '/platform/goods-price-range/delete',
    method: 'post',
    data: data
  })
}
